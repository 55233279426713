<template>
  <div class="contract-templates-page">
    <h1 class="title">{{$t('Contract Templates')}}</h1>
    <div v-for="(templateInfo,index) in templateFilesInfo" :key="index">
      <b-row class="template-section">
        <b-col class="my-4">
          <h2 class="section-title template">{{templateInfo.templateType}}</h2>
        </b-col>
      </b-row>
      <div class="d-flex justify-content-start">
        <p class="admin-aclaration">{{$t(`Templates max size is ${fileSizeLimit} MB`)}}</p>
      </div>
      <div class="d-flex justify-content-start">
        <RFButton class="my-1" label="Download" background="#24425B" @on-click="downloadContractTemplate(index)"/>
        <RFFile class="my-1" :accepted-files="acceptedFiles"  label="Upload template" background="#24425B"
                @file="uploadFile($event,templateInfo)"/>
      </div>
    </div>

    <b-row class="justify-content-end">
      <b-col cols="auto">
        <RFButton class="mt-4" label="Save" background="#5CB85C" v-if="!loading" @on-click="save"/>
        <RFLoader :size="30" color="#24425B" v-else/>
      </b-col>
    </b-row>

    <b-row class="template-section">
      <b-col class="my-4">
        <h2 class="section-title template">General Placeholders (valid on both templates)</h2>
      </b-col>
    </b-row>

    <div class="float:left ">
      <p>[NAME_SURNAME_TENANT1]: Name and  Surname of tenant 1</p>
      <p>[BIRTH_DATE_TENANT1]: Date of birth of tenant 1 in format DAY/MONTH/YEAR</p>
      <p>[BIRTH_PLACE_TENANT1]: City and country of birth of tenant 1</p>
      <p>[PASSPORT]: Passport number of tenant 1</p>
      <p>[STREET_CITY_ZIPCODE_COUNTRY_TENANT1]: Street, city, zip code and country of tenant 1</p>
      <p>[TAX_CODE_TENANT1]: Tax code number of tenant 1</p>
      <p>[FOGLIO_NUMBER]: Number of Foglio</p>
      <p>[MAPPALE_NUMBER]: Number of Mappale</p>
      <p>[SUB_NUMBER]: Number of Sublocazione</p>
      <p>[CATASTALE_AMOUNT]: Rendita catastale</p>
      <p>[START_DATE]: Starting date of contract</p>
      <p>[END_DATE]: Finishing date of contract</p>
      <p>[TOTAL_AMOUNT]: Amount for the unit*duration of the contract</p>
      <p>[TOTAL_AMOUNT_WRITTEN]: Amount for the unit * duration of the contract written down in letters</p>
      <p>[N_RATES]: Number of rates is equal to number of months the contract lasts</p>
      <p>[N_RATES_WRITTEN]: Number of rates is equal to number of months the contract lasts, written in letters</p>
      <p>[MONTHLY_RATE]: Amount of the single rate – monthly price of the unit</p>
      <p>[MONTHLY_RATE_WRITTEN]: Amount of the single rate – monthly price of the unit written down in letters</p>
      <p>[DEPOSIT_AMOUNT]: Amount equal to one month rent</p>
      <p>[DEPOSIT_AMOUNT_WRITTEN]: Amount equal to one month rent written down in letters</p>
      <p>[SERVICE_AMOUNT]: Service amount, fixed at €50. If it's a shared room, the phrase "a suddividere tra gli inquilini" will be added</p>
    </div>
    <b-row class="template-section">
      <b-col class="my-4">
        <h2 class="section-title template">Room Placeholders (valid only in room's template)</h2>
      </b-col>
    </b-row>

    <div class="float:left">
      <p>[CATEGORIA_NUMBER]: Number of Categoria</p>
    </div>

    <b-row class="template-section">
      <b-col class="my-4">
        <h2 class="section-title template">Apartment Placeholders (valid only in apartment's template)</h2>
      </b-col>
    </b-row>

    <div class="float:left">
      <p>[NAME_OWNER]: Name of owner</p>
      <p>[BIRTH_DATE_OWNER]: Date of birth of owner in format DAY/MONTH/YEAR</p>
      <p>[BIRTH_PLACE_OWNER]: City and country of birth of owner</p>
      <p>[ID_OWNER]: ID number</p>
      <p>[STREET_CITY_ZIPCODE_COUNTRY_TENANT1]: Street, city, zip code and country of owner</p>
      <p>[TAX_CODE_TENANT1]: Tax code number of owner</p>
      <p>[APARTMENT_ADDRESS]: Address of the apartment</p>
      <p>[APARTMENT_POSTAL_CODE]: Postal code of the apartment</p>
      <p>[APARTMENT_CITY]: City of the apartment</p>
      <p>[APARTMENT_VANI]: Vani of the apartment</p>
      <p>[APARTMENT_BAGNI]: Number of bathrooms of the apartment</p>
      <p>[APARTMENT_CAMERE]: Number of bedrooms of the apartment</p>
      <p>[CATEGORY]: Category</p>
      <p>[ONERI_AMOUNT]: Amount of oneri accessori</p>
      <p>[ONERI_AMOUNT_WRITTEN]: Amount of oneri accessori written in letters</p>
      <p>[HAS_GAS]: If the apartment has gas, the sentence "e gas" will be added. Otherwise a blank space will be added</p>
      <p>[SPESE_AMOUNT]: Amount of oneri accesori * duration of contract</p>
      <p>[SPESE_AMOUNT_WRITTEN]: Amount of oneri accesori * duration of contract written in letters</p>
      <p>[TOTAL_SPESE_AMOUNT]: (Amount for the unit + amount of oneri accesori) * duration of the contract</p>
      <p>[TOTAL_SPESE_AMOUNT_WRITTEN]: (Amount for the unit + amount of oneri accesori) * duration of the contract written in letters</p>
    </div>

  </div>
</template>

<script>
import {contractTemplateService} from "@/services/admin/contract-template-service";
import RFButton from "@/components/forms/RFButton";
import RFLoader from "@/components/forms/RFLoader";
import RFFile from "@/components/forms/RFFile";
import {docTypes} from "@/enums";
import {docExtensions} from "@/enums/document-extensions";
import {getAsset} from "@/helpers";

export default {
  name: 'Contract-Templates',
  components: {
    RFButton,
    RFLoader,
    RFFile,
  },
  data() {
    return {
      file: null,
      templatePath: null,
      loading: false,
      acceptedFiles: ['docx'],
      originalTemplatesFilesInfo:[],
      templateFilesInfo: [],
      templateTypes: [],
      fileSizeLimit: 100, //In MB
    }
  },
  async beforeMount() {
    await this.getContractTemplates()
  },
  methods:{
    uploadFile(event,templateInfo){
      if(event.size > this.fileSizeLimit * 1024 * 1024){
        this.$toasted.error(this.$i18n.t(`You have uploaded a file with size not allowed (must be lower or equals than ${this.fileSizeLimit} MB)`))
      }
      else{
        templateInfo.file = event
        templateInfo.modified = true
        templateInfo.pathname = URL.createObjectURL(templateInfo.file)
        console.log(templateInfo.pathname)
        this.$toasted.success(this.$i18n.t(`Template file correctly uploaded!`))
      }
    },
    async getContractTemplates(){
      const templatesFiles = await contractTemplateService.getTemplates()
      if(templatesFiles!==undefined && templatesFiles.length!==0){
        templatesFiles.forEach(templateFile => {
          let templateFileInfo = {
            pathname: templateFile.pathname,
            file: null,
            modified: false,
            templateType: templateFile.templateType,
          }
          let originalTemplateFileInfo = {
            pathname: templateFile.pathname,
            file: null,
            templateType: templateFile.templateType,
          }
          this.templateFilesInfo.push(templateFileInfo)
          this.originalTemplatesFilesInfo.push(originalTemplateFileInfo)
        })
      }
    },
    async save() {
      this.loading = true

      await Promise.all(
        this.templateFilesInfo.map(async (templateFileInfo,index)=>{
          if(templateFileInfo.modified){
            const form = new FormData()
            form.append('templateType', templateFileInfo.templateType)
            form.append('file', templateFileInfo.file)

            const {status} = await contractTemplateService.putTemplate(form)
            if(status === 200){
              templateFileInfo.modified = false
              this.$toasted.success(this.$i18n.t(`Template files correctly set!`))
            }
            else{
              this.$toasted.error(this.$i18n.t(`Template files correctly set!`))
            }
          }
        })
      )
      this.loading = false
    },
    downloadContractTemplate(templateIndex) {
      const link = document.createElement("a")
      link.href = getAsset(this.originalTemplatesFilesInfo[templateIndex].pathname)
      link.target = '_blank'
      document.body.appendChild(link)
      link.click()
    }
  }
}
</script>

<style lang="scss" scoped>
.contract-templates-page {
  .title {
    @include font-style($montserrat, 'semibold', $font-30);
    color: $dark-blue;
    text-transform: uppercase;
  }
  .template-section {
    margin-top: 20px;
    .template {
      border-bottom: 1px solid $dark-gray;
    }
    .section-title {
      @include font-style($montserrat, 'semibold', $font-22);
      color: $dark-blue;
      text-transform: uppercase;
      margin: 0;
    }
  }
  .placeholder {
    @include font-style($montserrat, 'semibold', $font-22);
    margin: 0;
  }
}
</style>
